
export default {
  
  showChange(item) {
    if(item && (/\r\n/g.test(item) || /\n/g.test(item) || /(\r\n)|(\n)/g.test(item) || /\s/g.test(item) )) {
      item = item.replace(/\r\n/g,"<br>")
      item = item.replace(/\n/g,"<br>");
      item = item.replace(/(\r\n)|(\n)/g,'<br>');
      item = item.replace(/\s/g,"&nbsp;");
    }
    return item
  },
  
  setCookie: function(c_name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = c_name + '=' + escape(value) + ';path=/'
      + ((expiredays == null) ? '' : '; expires=' + exdate.toGMTString());
  },

  getCookie: function(c_name) {
    var c_start;
    var c_end;
    if(document.cookie.length > 0) {
      c_start = document.cookie.indexOf(c_name + '=');
      if(c_start != -1) {
        c_start = c_start + c_name.length + 1;
        c_end = document.cookie.indexOf(';', c_start);
        if(c_end == -1)
          c_end = document.cookie.length;
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return '';
  },

  /**
   * formatDate(new Date(),"yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
   * formatDate(new Date(),"yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
   * formatDate(new Date(),"yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
   * formatDate(new Date(),"yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
   * formatDate(new Date(),"yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
   */
  formatDate: function formatDate(time,fmt) {
    if(!time) return '';
    var d;
    if(typeof time == 'string') {
      time = time.replace(/\-/g,'/').replace(/T/, ' ').substr(0, 19);
      d = new Date(time);
    } else if(typeof time == 'number') {
      d = new Date(time);
    } else if(typeof time == 'object') {
      if(time.getTime) {
        d = time;
      } else {
        d = new Date(time.time);
      }

    }
    fmt = fmt ? fmt : 'yyyy-MM-dd';
    var o = {
      'M+' : d.getMonth() + 1, //月份
      'd+' : d.getDate(), //日
      'h+' : d.getHours() % 12 == 0 ? 12 : d.getHours() % 12, //小时
      'H+' : d.getHours(), //小时
      'm+' : d.getMinutes(), //分
      's+' : d.getSeconds(), //秒
      'q+' : Math.floor((d.getMonth() + 3) / 3), //季度
      'S' : d.getMilliseconds() //毫秒
    };
    var week = {
      '0' : '\u65e5',
      '1' : '\u4e00',
      '2' : '\u4e8c',
      '3' : '\u4e09',
      '4' : '\u56db',
      '5' : '\u4e94',
      '6' : '\u516d'
    };
    if(/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    if(/(E+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[d.getDay() + '']);
    }
    for(var k in o) {
      if(new RegExp("("+ k +")").test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      }
    }
    return fmt;
  },

  concatFormData: function(form = {}) {
    var formData = new FormData();
    for(var item in form) {
      formData.append(item, form[item]);
    }
    return formData;
  },
  // 筛除空字符创的属性
  formatJSON: function(data = {}) {
    let resData = {};
    Object.keys(data).forEach(key => {
      if(data[key] !== '') {
        resData[key] = data[key];
      }
    });
    return resData;
  },
  convertBase64UrlToBlob: function(base64){
    let parts = base64.split(';base64,');
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;
    let uInt8Array = new Uint8Array(rawLength);
    for(let i = 0; i < rawLength; i++) {
      uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], {type: contentType})
  },
  //自定义滚动条事件
  gun: function(top) {
    var _this = this;
    var wrapDiv = document.getElementById("wrap");
    var contentDiv = document.getElementById("content");
    var sliderWrap = document.getElementById("sliderWrap");
    var slider = document.getElementById("slider");
    var scale = wrapDiv.clientHeight / contentDiv.offsetHeight;
    var h1 = sliderWrap.clientHeight * scale;
    var time = null;
    if (h1 < 50) {
      h1 = 50;
    }else if (scale >= 1) {
      sliderWrap.style.visibility = "hidden";
      slider.style.visibility = "hidden";
    }else {
      sliderWrap.style.visibility = "visible";
      slider.style.visibility = "visible";
    }
    slider.style.height = h1 +"px";
    if(top){
      var y=top;
    }else {
      var y = 0;
    }
    time = setInterval(function(){
      y += 10;
      if(y >= sliderWrap.clientHeight - slider.clientHeight){
        //滑块最多滑到最底部
        y = 0;
      }
      //更新滑块的位置
      slider.style.top = y +"px";
      scale = wrapDiv.clientHeight / contentDiv.offsetHeight;
      if(slider.style.visibility=="visible"){
        contentDiv.style.top = - y / scale +"px";
      }
    }, 2000)
    wrapDiv.onmousewheel = wrapDiv.onwheel = function(e){
      var event1 = event || e
      if (event.wheelDelta < 0) {
        //滑动条向下滚动
        y += 10;
      }else if (event.wheelDelta > 0) {
        //滑动条向上滚动
        y -= 10;
      }
      if (y <= 0) {
        //滑块最多滑到顶部
        y = 0;
      }
      if(y >= sliderWrap.clientHeight - slider.clientHeight){
        //滑块最多滑到最底部
        y = sliderWrap.clientHeight - slider.clientHeight;
      }
      //更新滑块的位置
      slider.style.top = y +"px";
      scale = wrapDiv.clientHeight / contentDiv.offsetHeight;
      if(slider.style.visibility=="visible"){
          contentDiv.style.top = - y / scale +"px";
      }
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  },
}
