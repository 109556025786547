 <template>
  <div class="common_title clearfix">
    <div class="flex pull-left">
      <h3 class="pull-left"><slot name="title"></slot></h3>
      <span v-if="!noShow" class="pull-left"><slot name="number"></slot></span>
      <div class="item_list">
        <span 
          :class="{ 'active': tabIndex == index }" 
          v-for="(item, index) in dataList" 
          :key="item.title"
          @click="tabEvent(index, item.type)">{{ item.title }}</span>
      </div>
    </div>
    <div class="pull-right">
      <slot name="search"></slot>
    </div>
     <!-- <template v-slot:search>
        <el-input v-model="value" placeholder="请输入关键词" size="large">
          <template #suffix>
            <svg-icon icon-class="manual"></svg-icon>
          </template>
        </el-input>
      </template> -->
  </div>
</template>

<script>
export default {
  props: ['noShow', 'dataList'],
  data() {
    return {
      tabIndex: 0
    }
  },
  methods: {
    tabEvent(index, key) {
      this.tabIndex = index;
      this.$emit('change', key)
    }
  }
}
</script>

<style lang='scss'>
  .common_title {
    margin-bottom: 20px;

    .flex {
      display: flex;
      align-items: center;

      & > h3 {
        line-height: 28px;
        font-size: 20px;
        color: #303133;
        margin-right: 15px;
        display: flex;
        align-content: center;

        & > img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }

      & > span {
        width: 40px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        line-height: 24px;
        font-size: 14px;
        text-align: center;
        color: #4e5969;
        display: block;
      }
    }

    .item_list {
      margin-left: 10px;

      & >span {
        padding: 3px 12px;
        font-size: 14px;
        color: #606266;
        line-height: 20px;
        margin-right: 4px;
        cursor: pointer;

        &:hover {
          background: #0072ef;
          border-radius: 4px;
          color: #fff;
        }
      }

      .active {
        background: #0072ef;
        border-radius: 4px;
        color: #fff;
      }
    }

    .el-input {
      .svg-icon {
        width: 20px;
        height: 20px;
        color: #303133;
      }
    }

    .el-button {
      width: 96px;
      height: 36px;
    }
    
  }
</style>