<template>
  <div v-if="list.length > 0" class="news_top">
    <div class="top_list" v-for="item in list" :key="item.id">
      <span>置顶</span>
      <p><el-link :href="item.url" target="_blank">{{ item.title }}</el-link></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.TopArticleList().then(res => {
        if(res.data.code == 200) {
          this.list = res.data.data
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .news_top {
    width: 1180px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 10px 0;
    margin-bottom: 10px;

    .top_list {
      display: flex;
      height: 40px;
      align-items: center;
      padding: 0 30px;

      & > span {
        display: block;
        width: 44px;
        height: 20px;
        background: #f26257;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        line-height: 20px;
        flex-shrink: 0;
        margin-right: 15px;
      }
      
      & > p {
        font-size: 14px;
        color: #1d2129;
        width: 1060px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>