<template>
  <div class="banner">
    <el-carousel trigger="click" height="354px" v-if="dataList.length > 0">
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <a :href="item.url">
          <img :src="baseUrl + item.picture" />
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: []
    }
  },
  created() {
    this.showPage();
  },
  methods: {
    showPage() {
      this.$API.CarouselList().then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data;
          if(res.data.data.length == 0) this.$emit('close')
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .banner {
    width: 1180px;
    height: 354px;
    border-radius: 10px;
    overflow: hidden;

    .el-carousel__item {
      img {
        width: 1180px;
        height: 354px;
      }
    }

    .el-carousel__button {
      width: 12px;
      height: 4px;
      border-radius: 2px;
    }
  }
</style>