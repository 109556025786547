<template>
  <div class="knowledge_base">
    <CommonTitle :noShow="true" :dataList="typeList" @change="typeChange">
      <template v-slot:title><img src="../../../assets/img/m_02.png" />{{ title }}</template>
      <template v-slot:search>
        <el-button @click="eventClick">查看更多</el-button>
      </template>
    </CommonTitle>
    <div v-if="dataList.length > 0" class="knowledge_list">
      <CommonKnowledgeCards 
        v-for="(item, index) in dataList" 
        :key="index" 
        :data="item"></CommonKnowledgeCards>
    </div>
    <NoData v-else></NoData>
  </div>
</template>

<script>
import CommonTitle from '@/components/CommonTitle'
import CommonKnowledgeCards from '@/components/CommonKnowledgeCards'
export default {
  components: {
    CommonTitle,
    CommonKnowledgeCards
  },
  data() {
    return {
      title: '知识库',
      typeList: [
        { title: '全部', type: null },
        { title: '政策法规', type: '/knowledgeBase/POLICY' },
        { title: '标准', type: '/knowledgeBase/STANDARD' },
        { title: '书籍', type: '/knowledgeBase/BOOK' },
        { title: '课程', type: '/knowledgeBase/COURSE' },
      ],
      dataList: [],
      type: null
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      let api = this.type != null ? 'KnowledgeBaseTypeOne' : 'KnowledgeBaseType'
      this.$API[api]({
        sort: this.type
      }).then(res => {
        if(res.data.code == 200){
          res.data.data.forEach(item => {
            item.coverUrl = item.coverUrl ? this.baseUrl + item.coverUrl : null
          })
          
          this.dataList = res.data.data
        }
      })
    },
    eventClick() {
      this.$router.push('/knowledgeBase/POLICY?pager=1')
    },
    typeChange(url) {
      if(!url) return;
      this.$router.push(url)
      // this.type = type;
      // this.showPage()
    }
  }
}
</script>

<style lang='scss'>
  .knowledge_base {
    .flex {
      line-height: 36px;
    }

    .knowledge_list {
      display: flex;
      flex-wrap: wrap;

      .common_knowledge_cards {
        width: 580px;
        margin-right: 20px;
        margin-bottom: 20px;

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:nth-child(3),
        &:nth-child(4) { 
          margin-bottom: 0;
        }

        
        .tip {
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        .tip1, h3 {
          overflow : hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .tip2 {
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

    }

    .no_data {
      background: #fff;
      border-radius: 4px;
      border: 1px solid #e4e7ed;
    }
  }
</style>