<template>
  <div class="type_show">
    <div class="type_item" 
      v-for="(item, index) in dataList" 
      :key="index"
      @click="eventClick(item.url)">
      <div>
        <h5>{{ item.title }}</h5>
        <p v-if="item.type">{{ numData[item.num] }}{{ item.type }}</p>
        <p v-else class="button">点击前往</p>
      </div>
      <img :src="item.imgUrl" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        { title: '政策法规', num: 'regulationCount', type: ' 条', imgUrl: require('@/assets/img/c_01.png'), url: '/knowledgeBase/POLICY' },
        { title: '标准', num: 'standardCount', type: ' 份', imgUrl: require('@/assets/img/c_02.png'), url: '/knowledgeBase/STANDARD' },
        { title: '书籍', num: 'bookCount', type: ' 本', imgUrl: require('@/assets/img/c_03.png'), url: '/knowledgeBase/BOOK' },
        { title: '课程', num: 'courseCount', type: ' 个', imgUrl: require('@/assets/img/c_04.png'), url: '/knowledgeBase/COURSE' },
        { title: '测评', num: 0, type: null, imgUrl: require('@/assets/img/c_05.png'), url: '/testReviews' },
      ],
      numData: {}
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    eventClick(url) {
      this.$router.push(url)
    },
    showPage() {
      this.$API.KnowledgeBaseTotal().then(res => {
        if(res.data.code == 200) {
          this.numData = res.data.data
        } 
      })
    }
  }
}
</script>

<style lang='scss'>
  .type_show {
    display: flex;

    .type_item {
      width: 220px;
      height: 90px;
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 10px;
      margin-right: 20px;
      display: flex;
      padding: 21px 20px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.05); 
      }

      &:last-child {
        margin-right: 0;
      }

      & > div {
        width: 120px;

        & > h5 {
          font-size: 16px;
          color: #000000;
          line-height: 22px;
          margin-bottom: 2px;
        }
        
        & > p {
          font-size: 14px;
          color: #606266;
          line-height: 20px;
        }

        .button {
          color: #0072EF;
        }
      }

      & > img {
        width: 58px;
        height: 48px;
      }
    }
  }
</style>