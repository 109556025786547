<template>
  <div id="home">
    <Banner v-if="visible" @close="visible = false"></Banner>
    <NewsTop></NewsTop>
    <TypeShow></TypeShow>
    <News></News>
    <KnowledgeBase></KnowledgeBase>
  </div> 
</template>

<script>
import Banner from './components/Banner.vue'
import TypeShow from './components/TypeShow.vue'
import News from './components/News.vue'
import KnowledgeBase from './components/KnowledgeBase.vue'
import NewsTop from '../notificationInformation/components/NewsTop.vue'
export default {
  components: {
    Banner,
    TypeShow,
    News,
    NewsTop,
    KnowledgeBase
  },
  data() {
    return {
      visible: true
    }
  }
}
</script>

<style lang='scss'>
  #home {
    padding: 20px 10px 50px;
    margin: 0 auto;
    width: 1200px;

    .banner,
    .news_top {
      margin-bottom: 20px;
    }
    

    .type_show,
    .news {
      margin-bottom: 50px;
    }
  }
</style>