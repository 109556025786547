<template>
  <div class="news">
    <CommonTitle :noShow="true" :dataList="typeList" @change="tabChange">
      <template v-slot:title><img src="../../../assets/img/m_01.png" />{{ title }}</template>
      <template v-slot:search>
        <el-button @click="eventClick(null)">查看更多</el-button>
      </template>
    </CommonTitle>
    <div class="card_list">
      <el-card shadow="hover" v-for="item in newsList" :key="item.id" @click="eventClick(item.id)">
        <h4>{{ item.title }}</h4>
        <p class="tip" v-html="utils.showChange(item.summary)"></p>
        <p class="date">{{ item.publishDate.split(' ')[0] }}</p>
      </el-card>
    </div>
  </div>
</template>

<script>
import CommonTitle from '@/components/CommonTitle'
import utils from '@/assets/js/utils'
export default {
  components: {
    CommonTitle
  },
  computed: {
    utils() { return utils }
  },
  data() {
    return {
      title: '最新资讯',
      typeList: [
        { title: '全部', type: null },
        { title: '通知公告', type: '0' },
        { title: '行业动态', type: '1'  },
        { title: '培训信息', type: '3'  },
        { title: '招贤纳士', type: '2'  },
      ],
      newsList: [],
      type: null
    }
  },
  mounted() {
    this.showPage();
  },
  methods: {
    eventClick(id = null) {
      this.$router.push(id  ? '/notificationInformationDetail/' + id : '/notificationInformation')
    },
    tabChange(key) {
      // this.type = key;
      // this.showPage();
      if(!key) return;
      this.$store.commit('news_type', key)
      this.$router.push('/notificationInformation')
    },
    showPage() {
      this.$API.ArticleList({
        status: 0,
        contentType: this.type,
        pageNum: 1,
        pageSize: 3,
      }).then(res => {
        if(res.data.code == 200) {
          this.newsList = res.data.data.list
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .news {
    .flex {
      line-height: 36px;
    }

    .card_list {
      display: flex;

      .el-card {
        width: 380px;
        height: 212px;
        border-radius: 4px;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        .el-card__body {
          padding: 30px 20px;
          cursor: pointer;

          h4 {
            font-size: 18px;
            line-height: 25px;
            min-height: 50px;
            color: #000000;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -webkit-line-clamp:2;
            margin-bottom: 15px;
          }

          .tip {
            font-size: 14px;
            color: #7b7b7b;
            line-height: 22px;
            min-height: 44px;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -webkit-line-clamp:2;
            margin-bottom: 35px;
          }

          .date {
            font-size: 12px;
            color: #707070;
            line-height: 18px;
          }
        }
      }
    }
  }
</style>