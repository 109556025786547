<template>
  <div class="common_knowledge_cards" @click="eventClick(data.id)">
    <img :src="data.coverAddress || data.picture || data.coverUrl || noCover" />
    <div>
      <h3>{{ data.title || data.courseName || data.name || data.standardNumber }}</h3>
      <div :style="{maxHeight: $route.name == 'home' ? '72px' : 'auto'}">
        <p class="tip" v-if="data.summary || data.sort == 0 || data.sort == 2" v-html="utils.showChange(data.summary || data.subTitle)"></p>
        <p class="tip1" v-if="data.chineseName || data.sort == 1">{{ data.chineseName ||data.subTitle }}</p>
        <p class="tip2" v-if="data.englishName">{{ data.englishName }}</p>
        <p class="tip2" v-if="data.teacher || data.sort == 3">来源：{{ data.courseSource || data.subTitle }}</p>
        <p class="tip2" v-if="data.teacher || data.sort == 3">讲师：{{ data.teacher || data.subTitle }}</p>
      </div>
      <p class="clearfix">
        <span class="pull-left" v-if="data.isEvaluation">测评范围</span>
        <el-button class="pull-right" type="text" color="#0072EF">查看详情 <el-icon><ArrowRightBold /></el-icon></el-button>
      </p>
    </div>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  props: ['data'],
  data() {
    return {
      noCover: require('@/assets/img/nocover.png')
    }
  },
  computed: {
    utils() { return utils }
  },
  methods: {
    eventClick(id) {
      let url = this.$route.params.type == 'POLICY' || this.data.sort == 0 ? 'policiesRegulationsDetail' : 
      this.$route.params.type == 'STANDARD' || this.data.sort == 1 ? 'standardDetail' : 
      this.$route.params.type == 'BOOK' || this.data.sort == 2 ? 'bookDetail' : 'courseDetail'
      window.open(`/${url}/${id}`)
    }
  }
}
</script>

<style lang='scss'>
  .common_knowledge_cards {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.05); 
    }

    & > img {
      width: 100px;
      height: 140px;
      border: 1px solid #ebebeb;
      border-radius: 2px;
      margin-right: 20px;
    }

    & > div {
      width: 410px;

      & > h3 {
        font-size: 18px;
        color: #000000;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: #7b7b7b;
        line-height: 24px;
      }

      & > p {
        margin-top: 10px;

        & >span {
          display: inline-block;
          font-size: 12px;
          text-align: center;
          color: #0072ef;
          line-height: 18px;
          width: 58px;
          height: 20px;
          background: #f2f8ff;
          border: 1px solid #0072ef;
          border-radius: 4px;
          vertical-align: 1px;
        }
      }
    }

    

    .el-button--text {
      padding-bottom: 0;
      padding-top: 0;
      height: 20px;

      .el-icon {
        height: 10px;
        width: 10px;
        line-height: 10px;
        margin-left: 5px;

        svg {
          height: 10px;
          width: 10px;
        }
      }
    }

  }
</style>